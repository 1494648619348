export class OffCanvas {
    // rewrite the below class for jquery:
    constructor(el) {
        this.selector = el;
        this.isOpen = false;
        this.addListeners();
    }

    addListeners() {
        $(document).on("click.canvas", '[data-open-canvas]', e => {
            e.preventDefault();
            this.target = $(e.target).closest('[data-open-canvas]');

            if (this.target && !this.isOpen) {
                this.openCanvas(this.target);
            }
        });

        $(document).on("click.canvas", '[data-close-canvas]', e => {
            e.preventDefault();
            this.target = $(e.target).closest('[data-close-canvas]');

            if (this.target && this.isOpen) {
                this.closeCanvas();
            }
        });

        document.addEventListener('keydown', (e) => {
            if (e.key === "Escape") {
                this.closeCanvas();
            }
        });
    }

    openCanvas(target){
        this.isOpen = true;
        this.selector.addClass('offcanvas--open');
        $("html").addClass('offcanvas--open');
    }

    closeCanvas(){
        this.isOpen = false;
        this.selector.removeClass('offcanvas--open');
        $("html").removeClass('offcanvas--open');
    }
}
