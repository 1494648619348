$(() => {
    function initHero() {
        const hero = document.querySelector('.js-hero');

        if (hero && 'IntersectionObserver' in window) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0) {
                        document.body.classList.add('is-hero-visible');
                    } else {
                        document.body.classList.remove('is-hero-visible');
                    }
                });
            });

            observer.observe(hero);
        }
    }

    // init on page load
    initHero();

    // custom event
    $(window).on('initHero', () => {
        initHero();
    });

    // initialize again when viewport width has changed
    $(window).on('viewportWidthHasChanged', () => {
        initHero();
    });
});
