/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Teaser parallax scroll effect

*/
import simpleParallax from 'simple-parallax-js';

function initParallaxLib(image) {
    new simpleParallax(image, {
        scale: 1.3,
        orientation: 'down',
        transition: 'ease-out',
    });
}

function initParallax() {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobile === false) {
        $('.teaser--parallax .teaser__image img').each(function() {
            // init directly if image is already loaded or add event listener for lazyloaded event
            if ($(this).hasClass('lazyloaded')) {
                initParallaxLib($(this).get(0));
            } else {
                $(this).on('lazyloaded', () => {
                    initParallaxLib($(this).get(0));
                });
            }
        });
    }
}

$(() => {
    // init on page load
    initParallax();

    // custom event
    $(window).on('initParallax', initParallax);

    // re-init after cms page refresh
    if (window.CMS) {
        CMS.$(window).on('cms-content-refresh', initParallax);
    }
});
