function initLocationSelector() {
    $('.js-switch-location').on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
        $('.js-location-item')
            .addClass('hidden')
            .filter(`[id="location-cta-${e.target.value}"]`)
            .removeClass('hidden');
    });
}

$(() => {
    initLocationSelector();

    // custom event
    $(window).on('initLocationSelector softpage:opened', initLocationSelector);

    // re-init after cms page refresh
    if (window.CMS) {
        CMS.$(window).on('cms-content-refresh', initLocationSelector);
    }
});
