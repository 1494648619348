import '/static/js/libs/midnight.min';


$(() => {
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Header

    IMPORTANT: If the 'header buffer' is enabled, the scroll distance breakpoints have to be the same height (AT LEAST), otherwise the buffer becomes visible.

    */

    function initHeader() {
        const header = document.querySelector('.site-header');

        if (document.body.classList.contains('tpl-home')) {
            $(header).midnight({
                defaultClass: 'midnightHeader--default',
            });
        }
    }

    // init on page load
    initHeader();

    // custom event
    $(window).on('initHeader', () => {
        initHeader();
    });

    // initialize again when viewport width has changed
    $(window).on('viewportWidthHasChanged', () => {
        initHeader();
    });
});
