window.addEventListener('load', () => {
    const cookiebanner = document.querySelector('[data-cookie-msg]');

    if (document.cookie.split(';').filter((item) => item.trim().startsWith('cookie-message=')).length) {
        cookiebanner.classList.add("hide");
    } else {
        cookiebanner.classList.remove("hide");
    }

    document.querySelector("[data-cookie-msg-close]").addEventListener('click', () => {
        var durationInDays = 60;
        var cookieDuration = durationInDays * 24 * 60 * 60;

        if (!document.cookie.split(';').filter((item) => item.trim().startsWith('cookie-message=')).length) {
            document.cookie = "cookie-message=true;max-age=" + cookieDuration + ";path=/";

            cookiebanner.classList.add("hide");
        }
    });
});
